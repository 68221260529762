import React from 'react'
import { TextInput, SimpleForm, Edit, NumberInput, Datagrid, ReferenceManyField, TextField, DateField } from 'react-admin'


const UserEdit = props => (
  <Edit {...props} title='Editando usuario'>
    <SimpleForm>

      <TextInput source="nombreCompleto"  fullWidth/>
      <TextInput source="rut" />   
      <TextInput source="email" />
      <TextInput source="comuna" fullWidth />
      <TextInput source="direccion" fullWidth />
      <TextInput source="area" label="Area"    fullWidth/>      

      <TextInput source="pass2" label="Contraseña" />
      <TextInput source="npersonas" label="Número de personas" />
      <NumberInput source="totalpuntos" label="Puntos Totales" />
      <ReferenceManyField sort={{ field: 'fechaBoleta', order: 'DESC' }} label="Ingreos" reference="ingresos" target="rut">
        <Datagrid> <DateField source="fechaBoleta" />
          <DateField source="fechaIngreso" />
          <TextField source="m3" />
          <TextField source="puntos" />
          <TextField source="haciendo" />


        </Datagrid>
      </ReferenceManyField>
    </SimpleForm>
  </Edit>
)
export default UserEdit
