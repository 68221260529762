import React, { Fragment } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField
} from '@mui/material'
import { makeStyles } from '@mui/styles';
import { auth } from "../../firebase";
import { useState } from "react";
import { useRef } from "react";

import { sendPasswordResetEmail } from 'firebase/auth'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    background: '#ccc',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
  }
}))

export default () => {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState('')

  const [toastOpen, setToastOpen] = React.useState(false)
  const [toastMessage, setToastMessage] = React.useState('')

  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }
  const validateEmail = (email) => {
    // Expresión regular para validar el correo electrónico
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  };
  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = async () => {
    const isValidEmail = validateEmail(email);
if(isValidEmail){
    console.log('sending email to: ', email)
    try {
      await sendPasswordResetEmail(auth,email).then(() => {
        console.log("Email de recuperación de contraseña enviado");
        setOpen(false)
        setToastOpen(true)
        setToastMessage(
          'Se ha enviado un correo donde encontrara instrucciones para recuperar su contraseña.'
        )      })
      .catch((error) => {
        console.log("Error al enviar el email de recuperación de contraseña: ", error);
        // Aquí puedes mostrar un mensaje de error al usuario
      });
    
    } catch (error) {
      setToastOpen(true)
      setToastMessage(error.message)
    }
  }else{
    
  }
  }


  const emailRef = useRef(null);


  const [emailError, setEmailError] = useState("");

  const handleOnChange = (event) => {
    const email = event.target.value;
    emailRef.current = event.target.value;

    // Valida el correo electrónico
    const isValidEmail = validateEmail(email);
    // Actualiza la referencia y el estado del mensaje de error
    emailRef.current = email;
    setEmailError(isValidEmail ? "" : "Correo electrónico no válido");
    setEmail(email)

  };

  const handleToastClose = () => {
    setToastOpen(false)
    setToastOpen(false)
  }

  return (
    <Fragment>
      <Button
        type='button'
        variant='contained'
        color='secondary'
        className={classes.submit}
        onClick={handleClickOpen}
      >
        Olvido su contraseña?
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Recuperar contraseña!</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Para recuperar su contraseña debe ingresar su correo.
          </DialogContentText>
          <TextField
            id='outlined-basic'
            label='Email'
            type='email'
            variant='outlined'
            style={{ width: '100%' }}
            onChange={handleOnChange}
            error={!!emailError} // Agrega el estado de error
            helperText={emailError} // Agrega el mensaje de error
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit} color='primary' autoFocus>
            Enviar contraseña a su correo
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={toastOpen}
        onClose={handleToastClose}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        message={toastMessage}
      />
    </Fragment>
  )
}
