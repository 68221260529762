import React from "react";
import { Show, TextField, SimpleShowLayout, ReferenceField, NumberField, DateField } from "react-admin";

const RegisterShow = props => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />     <TextField source="rut" />
      <DateField source="fechaIngreso" />
      <DateField source="fechaBoleta" />
      <TextField source="m3" />
      <TextField source="rutaBoleta" />

      <NumberField source="puntos" />
    </SimpleShowLayout>
  </Show>
);
export default RegisterShow;
