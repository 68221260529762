import React, { useState } from "react";
import { useLogin } from "react-admin";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { notification } from "antd";
import { checkAdminByEmail } from "./fn";

function MyLoginPage(props) {
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  const [open, setOpen] = React.useState(false);

  const login = useLogin();

  const submit = async (event) => {
    event.preventDefault();
    if (username !== "" && password !== "") {
      const credentials = { username: username, password: password };
      try {
        const check = await checkAdminByEmail(username);

        if (check === true) {
          const res = await login(credentials);
          // el usuario es un administrador, haz algo aquí
        } else {
          notification.error({
            message: "Se ha producido un error",
            description:
              "Solo los administradores tienen acceso a esta sección.",
          });
          return false;
        }
      } catch (error) {
        console.log(error);
        if (error.code === "auth/too-many-requests") {
          notification.error({
            message: "Se ha producido un error",
            description:
              "El acceso a esta cuenta se ha deshabilitado temporalmente debido a muchos intentos fallidos de inicio de sesión. Puede restaurarlo inmediatamente restableciendo su contraseña o puede intentarlo de nuevo más tarde.",
          });
        } else if (error.code === "auth/user-not-found") {
          notification.error({
            message: "Se ha producido un error",
            description:
              "No hay ningún registro de usuario correspondiente a este identificador. Es posible que el usuario se haya eliminado.",
          });
        } else if (error.code === "auth/wrong-password") {
          notification.error({
            message: "Se ha producido un error",
            description:
              "La contraseña no es válida o el usuario no tiene una contraseña.",
          });
        } else {
          notification.error({
            message: "Se ha producido un error",
            description: error.message,
          });
        }
      }
    } else {
      setOpen(true);
    }
  };

  const handleChangeUsuario = (event) => {
    setOpen(false);
    setUsername(event.target.value);
  };
  const handleChangePassword = (event) => {
    setOpen(false);
    setPassword(event.target.value);
  };

  return (
    <form noValidate onSubmit={submit}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="Usuario"
        label="Usuario"
        name="Usuario"
        autoComplete="Usuario"
        autoFocus
        onChange={handleChangeUsuario.bind(this)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="password"
        label="Contraseña"
        type="password"
        id="password"
        autoComplete="Contraseña"
        onChange={handleChangePassword.bind(this)}
      />
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Recordar Contraseña"
      />
      <Collapse in={open}>
        <Alert
          variant="filled"
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Debe ingresar el usuario y contraseña
        </Alert>
      </Collapse>

      <Button type="submit" fullWidth variant="contained" color="primary">
        Enviar
      </Button>
    </form>
  );
}

export default MyLoginPage;
