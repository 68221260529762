import React from 'react'
import { TextInput, SimpleForm, NumberInput, Create } from 'react-admin'


const UserEdit = props => (
  <Create {...props} title='Editando usuario'>
    <SimpleForm>
      <TextInput source="nombreCompleto"  fullWidth/>
      <TextInput source="rut" /> 
        <TextInput source="email"  fullWidth/>
      <TextInput source="comuna" />
      <TextInput source="direccion"  fullWidth/>
      <TextInput source="area" label="Area"    fullWidth/>      
      <TextInput source="pass2" label="Contraseña" />
      <TextInput source="npersonas" label="Número de personas" />
      <NumberInput source="totalpuntos" label="Puntos Totales" />



    </SimpleForm>
  </Create>
)
export default UserEdit
