import React from 'react'
import { TextInput, SimpleForm, NumberInput, Create, DateInput, ImageField } from 'react-admin'


const IdeaEdit = props => (
  <Create {...props} title='Editando Ideas'>
      <SimpleForm>
      <TextInput source="rut" />
        <TextInput source="name" label={"Nombre "} fullWidth/>
        <TextInput source="email" />
        <DateInput source="date" label="Fecha" />
        <TextInput source="title" label="Título de la Idea" fullWidth />
        <TextInput source="descripcion" label="Descripción de la idea"  fullWidth={true} multiline />
        <TextInput source="url_image" label="Url de la imagen" fullWidth />
        <TextInput source="puntos" label="Puntos Asignados" />
      </SimpleForm>
  </Create>
)
export default IdeaEdit
