import "./App.css";
import * as React from "react";
import { Admin, Resource } from "react-admin";
import { authProvider, dataProvider } from "./firebase";
import Users from "./pages/users";
import Register from "./pages/ingresos";
import Ideas from "./pages/ideas";
import Inicio from "auth/inicio";

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={Inicio}
  >
    <Resource
      name="usuarios"
      list={Users.list}
      edit={Users.edit}
      show={Users.show}
      create={Users.create}
      options={Users.options}
    />
    <Resource
      name="ingresos"
      list={Register.list}
      edit={Register.edit}
      show={Register.show}
      options={Register.options}
    />
    <Resource
      name="ideas"
      list={Ideas.list}
      edit={Ideas.edit}
      show={Ideas.show}
      create={Ideas.create}
      options={Ideas.options}
    />
  </Admin>
);

export default App;
