import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from "react-admin-firebase";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const config = {
  apiKey: "AIzaSyDwSYo03WMvoHTOFzc1HoPN7_1SMqvToC4",
  authDomain: "desafio-100-l.firebaseapp.com",
  projectId: "desafio-100-l",
  storageBucket: "desafio-100-l.appspot.com",
  messagingSenderId: "696699792307",
  appId: "1:696699792307:web:7a5428034362c981a6b9c6",
  measurementId: "G-431CJ1JTS0"

};

const options = {
  watch: ["posts", "comments"],
  logging: true,
};

export const dataProvider = FirebaseDataProvider(config, options);
export const authProvider = FirebaseAuthProvider(config, options);
export const app = initializeApp(config);
export const auth = getAuth(app);
