import React from 'react'
import { TextInput, SimpleForm, NumberInput, Create, DateInput } from 'react-admin'


const RegisterCreate = props => (
  <Create {...props} title='Editando Registro'>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="rut" />
      <DateInput source="fechaIngreso" fullWidth />
      <DateInput source="fechaBoleta" />
      <TextInput source="m3" />
      <TextInput source="rutaBoleta" fullWidth />
      <NumberInput source="puntos" />

    </SimpleForm>
  </Create>
)
export default RegisterCreate
