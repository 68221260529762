import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  usePermissions,
  Filter,
  TextInput,
  EmailField,
} from "react-admin";
import _get from "lodash/get";

const UsersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nombre" source="nombreCompleto" alwaysOn />
    <TextInput label="email" source="email" alwaysOn />
    <TextInput label="Rut" source="rut" alwaysOn />
  </Filter>
);

export const UserList = (props) => {
  const { permissions } = usePermissions();

  return (
    <List {...props} title="Listado de Usuarios" filters={<UsersFilter />}>
      <Datagrid rowClick="edit">

        
        <TextField source="nombreCompleto" label="Nombre Completo" />
        <EmailField source="email"  label="Email" />
        <TextField source="rut" label="Rut" />
        <TextField source="area" label="Area"  />

        <TextField source="totalpuntos"   label="Puntos Totales" />

 
      </Datagrid>
    </List>
  );
};

export default UserList;
