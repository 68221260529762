import React from "react";
import { Show, TextField, SimpleShowLayout, NumberField, EmailField } from "react-admin";

const UserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>

      <TextField source="nombreCompleto" label="Nombre Completo" />

      <TextField source="rut"  label="Rut" />
      <EmailField source="email" label="Email"/>
      <TextField source="comuna"  label="Comuna"/>
      <TextField source="direccion" label="Dirección" />
      <TextField source="npersonas" label="N° de personas" />
      <NumberField source="totalpuntos" />


    </SimpleShowLayout>
  </Show>
);
export default UserShow;
