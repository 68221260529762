import edit from './edit'
import list from './list'
import show from './show'
import icon from './icon'
import create from './create'
export default {
  list: list,
  edit: edit,
  icon: icon,
  show: show,
  create: create,
  options: { label: 'Usuarios' }
}
