import React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  ReferenceField,
  usePermissions,
  Filter,
  TextInput,
  EmailField,
  DateField,
} from "react-admin";
import _get from "lodash/get";

const IdeasFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nombre" source="name" alwaysOn />
    <TextInput label="email" source="email" alwaysOn />
    <TextInput label="Rut" source="rut" alwaysOn />
  </Filter>
);

export const IdeaList = (props) => {
  const { permissions } = usePermissions();

  return (
    <List {...props} title="Listado de ideas" filters={<IdeasFilter />}>
  
        <Datagrid rowClick="edit">
        <TextField source="name"  label="Nombre Completo"/>
      <EmailField source="email" label="Email" />
      <DateField source="date"  label="Fecha"/>
      <TextField source="title" label="Título" />
      <TextField source="descripcion" label="Descripción" />
      <TextField source="puntos" label="Puntos Asignados"></TextField>
        </Datagrid>
  
    </List>
  );
};

export default IdeaList;
