import React from "react";
import { Show, TextField, SimpleShowLayout, ReferenceField, NumberField, EmailField, DateField, ImageField } from "react-admin";

const IdeaShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="rut" />
      <TextField source="name"  label="Nombre Completo"/>
      <EmailField source="email" label="Email" />
      <DateField source="date"  label="Fecha"/>
      <TextField source="title" label="Título" />
      <TextField source="descripcion" label="Descripción" />
      <ImageField source="url_image" title="title" />

      <TextField source="puntos" label="Puntos Asignados"/>


    </SimpleShowLayout>
  </Show>
);
export default IdeaShow;
