import React from 'react'
import { TextInput, SimpleForm, Edit, NumberInput,DateInput, ReferenceManyField, Datagrid, TextField} from 'react-admin'


const RegisterEdit = props => (
  <Edit {...props} title='Editando usuario'>
       <SimpleForm>
       <TextInput source="id" />
      <TextInput source="rut" />
      <DateInput source="fechaIngreso" fullWidth />
      <DateInput source="fechaBoleta" />
      <TextInput source="m3" />
      <TextInput source="rutaBoleta" fullWidth />
      <NumberInput source="puntos" />

            
        </SimpleForm>
  </Edit>
)
export default RegisterEdit
