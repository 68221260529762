import React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Login from "../login";
import ForgotPasswordButton from "./CustomForgotPassword";

import { makeStyles } from "@mui/styles";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © 2023 Olivos Riego. Todos los derechos reservados."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    background: "#fff",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "#ffb31d",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    backgroundColor: "#ffb31d",
  },
}));

const PageCharge = (props) => {
  if (props.pageInit === "Login") {
    return <Login PageCharge={PageCharge}></Login>;
  }
};

function Inicio(props) {
  const [page, setPage] = React.useState("Login");
  const classes = useStyles();

  const ChangeRegister = () => {
    setPage("Register");
  };
  const ChangeLogin = () => {
    setPage("Login");
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        xs={false}
        sm={4}
        md={7}
        className={classes.image}
      >
        <img width={300} src="/img/login.png"></img>
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Administrador Desafio 100L
          </Typography>

          <PageCharge pageInit={page}></PageCharge>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid margin={2}>
              <ForgotPasswordButton {...props} />
            </Grid>
          </Grid>

          <Box mt={5}>
            <Copyright />
          </Box>
        </div>
      </Grid>
    </Grid>
  );
}

export default Inicio;
