import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  DateField,
  NumberField,
  ReferenceOneField,
  ReferenceField,
  ReferenceManyField,
  FunctionField
} from "react-admin";
import _get from "lodash/get";

const RegisterFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Rut" source="rut" alwaysOn />
  </Filter>
);

export const RegisterList = (props) => (
  <List {...props} title="Listado de registros" filters={<RegisterFilter />}>
    <Datagrid rowClick="edit">
      <FunctionField
    label="fechaIngreso"
    render={record =>{
      const date = new Date(record.fechaIngreso);
      return date.toLocaleDateString()
    } }
/>;
      <TextField source="rut" />
      <DateField source="fechaBoleta" />
      <ReferenceField label="Nombre Completo" source="rut" reference="usuarios">
                <TextField source="nombreCompleto" />
            </ReferenceField>
      <TextField source="m3" />
      <NumberField source="puntos" />
    </Datagrid>
  </List>
);

export default RegisterList;
