import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";

async function checkAdminByEmail(email) {
  const db = getFirestore();
  const usersRef = collection(db, "usuarios");
  const q = query(
    usersRef,
    where("email", "==", email),
    where("isAdmin", "==", true)
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.size > 0;
}

export { checkAdminByEmail };
